/**
 * Module definition and dependencies
 */
angular.module('App.InfosLot', [
    'App.InfosLot.Controller',
  ])



  /**
   * Route component
   */
  .component('infosLot', {
    controller: 'InfosLotCtrl',
    templateUrl: 'infos-lot/infos-lot.html',
    bindings: {
      transaction: '<',
    }

  })
