/**
 * Module definition and dependencies
 */
angular.module('App.Home.Controller', [
    'transactionsService.Service'
  ])

  /**
   * Controller
   */
  .controller('HomeCtrl', ['Transactions',
    '$scope',
    '$state',
    function (Transactions, $scope, $state) {
      this.redirection = (transactions) => {
        if (transactions.length === 1) {
          return $state.go('monLot', {
            id: transactions[0].id
          });
        }
        return $state.go('mes-projets');
      }
      $scope.$on('transactionsGet', () => {
        $scope.listTransactions = Transactions
          .getTransactions();
        this.redirection($scope.listTransactions);
      });

      this.$onInit = () => {
        $scope.listTransactions = Transactions
          .getTransactions();
        this.redirection($scope.listTransactions);
      };
    },
  ])
