/**
 * Module definition and dependencies
 */
angular.module('App.Messages.Controller', [
    'transactionsService.Service',
    'actionsService.Service',
    'messageService.Service',
    'profilService.Service',
    'ngTable',
  ])

  /**
   * Controller
   */
  .controller('MessagesCtrl', ['$sce', 'Config', 'Actions', 'Message',
    '$scope', 'Profil', '$rootScope', '$state',
    function ($sce, Config, Actions, Message, $scope, Profil, $rootScope,
      $state) {
      const self = this;
      self.url = Config.SIGNATURE_URL;
      $scope.$on('profilGet', () => {
        this.profil = Profil.getProfil();
      });
      self.getMessages = () => {
        $scope.loader = true;
        Actions.query({
          'limit': 99,
          // filtre l'historique pour afficher les message envoyé au vendeur
          'qualification_id': '34,35,45',
        }).then(() => {
          $scope.loader = false;
          self.messages = Actions.get();
        });
      }
      self.$onInit = () => {
        if (!$rootScope.stateParamsId) {
          return $state.go('mes-projets');
        }
        self.sent = 'reception';
        self.profil = Profil.getProfil();
        self.getMessages();
      };
      self.send = () => {
        Message.save({
          'message': $scope.msgSend,
          'sujet': 'message',
          'membre_id': self.profil.interlocuteurs_ids[0]
        }).then(() => {
          $scope.msgSend = null;
          self.getMessages();
        });
      }
      self.showDateEcheance = (item) => {
        //afficher la date_echeance si type_id = 20/21
        const showDateEcheanceArray = [20, 21];
        if (showDateEcheanceArray.indexOf(item.type_id) > -1 && item
          .date_creation.date) {
          return ' le ' + moment(item.date_creation.date).format(
            'DD/MM/YYYY à HH:mm');
        }
        return '';
      }
    }
  ]);
