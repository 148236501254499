/**
 * Module definition and dependencies
 */
angular.module('App.InfosLot.Controller', [])

  /**
   * Controller
   */
  .controller('InfosLotCtrl', [
    function () {



    }
  ])
