/**
 * Module definition and dependencies
 */
angular.module('App.Budget.Controller', [
    'transactionsService.Service',
    'echeancesService.Service',
  ])

  /**
   * Controller
   */
  .controller('BudgetCtrl', ['Transactions', 'Echeance',
    function (Transactions, Echeance) {
      var self = this;
      self.currentDate = new Date();
      self.echeance = [{
          total_en_attente: 150000,
          echeances: [{
              name: "1",
              delais: {
                value: '',
                duration: ''
              },
              echeanceDate: '',
              taux: 20,
              StatutEcheance: {
                name: 'En cours',
              },
              echeance: {
                total_en_attente: 150000,
              },
              montant: 20000,
            },
            {
              name: "2",
              delais: {
                value: '',
                duration: ''
              },
              echeanceDate: {
                date: '2019-06-03 10:17:00.000000',
                timezone: 'Europe/Berlin',
                timezone_type: 3
              },
              taux: 40,
              StatutEcheance: {
                name: 'En cours',
              },
              montant: 35000,
            },
            {
              name: "3",
              delais: {
                value: '',
                duration: ''
              },
              echeanceDate: '',
              taux: 60,
              StatutEcheance: {
                name: 'En cours',
              },
              montant: 55000,
            }
          ]

        }

      ]

    }
  ])
  .controller('BudgetTransactionCtrl', ['Echeance',
    function (Echeance) {
      var self = this;

      self.$onInit = function () {

      };

    }
  ]);
