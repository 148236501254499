/**
 * Module definition and dependencies
 */
angular.module('commercialisateurs.Service', [
    'ngResource',
  ])

  /**
   * Service
   */
  .factory('Commercialisateur', ['$resource', 'Config', function ($resource,
    Config) {
    const self = this;
    const commercialisateurApi = $resource(Config.API_BASE_URL +
      '/commercialisateurs/:commercialisateurId', {
        commercialisateurId: '@commercialisateurId'
      }, {
        query: {
          isArray: false,
        },
      });
    self.commercialisateur = null;
    return {
      get: (params) => {
        return new commercialisateurApi().$get(params).then(response => {
          self.commercialisateur = response;
        }).catch((err) => {
          console.log('promise error', err);
        });
      },
      getCommercialisateur: () => {
        return self.commercialisateur;
      },
    };

  }])
