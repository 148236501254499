/**
 * Module definition and dependencies
 */
angular.module('transactionsService.Service', ['ngResource'])

  /**
   * Service
   */
  .factory('Transactions', ['$resource', 'Config', '$filter', '$rootScope',
    function (
      $resource,
      Config, $filter, $rootScope) {
      const self = this;
      let transactionsApi = $resource(Config.API_BASE_URL +
        '/clients/transactions', {}, {
          query: {
            isArray: false,
          },
        });

      transactionsApi.prototype.createOrUpdate = () => {
        if (this.id) {
          return this.$update();
        }
        return this.$save();

      }
      self.listTransactions = [];
      return {
        getTransactions: () => {
          return self.listTransactions;
        },
        getTransactionById: (id) => {
          self.transaction = $filter('filter')(self.listTransactions, {
            id: id
          });
          return self.transaction[0];
        },
        setTransactionProgramme: (programme, key) => {
          self.listTransactions[key].programme = programme;
        },
        setTransactionProgrammeActualites: (programme, key) => {
          self.listTransactions[key].programmeActualites = programme;
          $rootScope.$broadcast('transactionsGetProgrammeActualites');
        },
        setTransactionProgrammeDocument: (programme, key) => {
          self.listTransactions[key].programmeDocuments = programme;
        },
        setTransactionDocumentLot: (programme, key) => {
          self.listTransactions[key].lotDocuments = programme;
        },
        setTransactionProgrammeTimeline: (programme, key) => {
          self.listTransactions[key].programmeTimeline = programme;
        },
        setTransactionProgrammeMedias: (programme, key) => {
          self.listTransactions[key].programmeMedias = programme;
        },
        setTransactionLot: (lot, key) => {
          self.listTransactions[key].lot = lot;
        },
        setTransactionLotDetails: (lot, key) => {
          self.listTransactions[key].lotDetails = lot;
        },
        setTransactionConfigurations: (configuration, key) => {
          self.listTransactions[key].configurations = configuration;
          $rootScope.$broadcast('configurationsGet');
        },
        query: (params) => {
          params=Object.assign({limit:100},params);
          return transactionsApi
            .query(params).$promise
            .then(response => {
              self.listTransactions = response.data;
              $rootScope.$broadcast('transactionsGet');
            })
            .catch((err) => {
              console.log('promise error', err);
            });
        },
      };
    }
  ])
  .factory('Transaction', ['$resource', 'Config', function (
    $resource,
    Config) {
    const self = this;
    let transactionsApi = $resource(Config.API_BASE_URL +
      '/clients/transactions/:transactionId', {
        transactionId: '@transactionId'
      }, {
        query: {
          isArray: false,
        },
      });

    self.transaction = null;
    return {
      get: (params) => {
        return new transactionsApi().$get(params).then(response => {
          self.transaction = response;
        }).catch((err) => {
          console.log('promise error', err);
        });
      },
      getTransaction: () => {
        return self.transaction;
      },
    };
  }])
