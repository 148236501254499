/**
 * Module definition and dependencies
 */
angular.module('programmesService.Service', [
    'ngResource',
  ])

  /**
   * Service
   */
  .factory('AlertesProgrammes', ['$resource', 'Config', function ($resource,
    Config) {
    const self = this;
    const alertesProgrammesApi = $resource(Config.API_BASE_URL +
      '/clients/programmes/alertes', {}, {
        query: {
          isArray: false,
        }
      });
    self.alertesProgrammes = [];
    return {
      getTransactions: () => {
        return self.alertesProgrammes;
      },
      query: () => {
        return alertesProgrammesApi
          .query().$promise
          .then(response => {
            self.alertesProgrammes = response.data;
          })
          .catch((err) => {
            console.log('promise error', err);
          });
      },
    };
  }])
  .factory('Programme', ['$resource', 'Config', '$rootScope', function (
    $resource, Config, $rootScope) {
    const self = this;
    let programmeApi = $resource(Config.API_BASE_URL +
      '/programmes/:programmeId', {
        programmeId: '@programmeId',
      }, {
        query: {
          isArray: false,
        }
      });
    self.programme = null;
    return {
      get: (params) => {
        return new programmeApi().$get(params).then(response => {
          self.programme = response;
          $rootScope.$broadcast('programmeGet');
        }).catch((err) => {
          console.log('promise error', err);
        });
      },
      getProgramme: () => {
        return self.programme;
      },
    };
  }])
  .factory('ProgrammeContenus', ['$resource', 'Config', function ($resource,
    Config) {
    const self = this;
    let programmeContenusApi = $resource(Config.API_BASE_URL +
      '/programmes/contenus/:programmeId', {
        programmeId: '@programmeId',
      }, {
        query: {
          isArray: false,
        }
      });
    self.programmeContenusApi = [];
    return {
      getProgrammeContenus: () => {
        return self.programmeContenusApi;
      },
      query: (params) => {
        return programmeContenusApi
          .query(params).$promise
          .then(response => {
            self.programmeContenusApi = response.data;
          })
          .catch((err) => {
            console.log('promise error', err);
          });
      },
    }
  }])

  .factory('ProgrammeImages', ['$resource', 'Config', function ($resource,
    Config) {
    const self = this;
    let programmeImagesApi = $resource(Config.API_BASE_URL_PUBLIC +
      '/promoteurs/:promoteurId/programmes/:programmeId', {
        programmeId: '@programmeId',
        promoteurId: '@promoteurId',
      }, {
        query: {
          isArray: true,
        }

      });
    self.programmeContenusApi = [];
    return {
      getProgrammeContenus: () => {
        return self.programmeContenusApi;
      },
      get: (params) => {
        return new programmeImagesApi().$get(params).then(response => {
          self.programmeContenusApi = response;
        }).catch((err) => {
          console.log('promise error', err);
        });
      }
    }
  }])
  .factory('ProgrammeActualites', ['$resource', 'Config', function ($resource,
    Config) {
    const self = this;
    let api = $resource(Config.API_BASE_URL +
      '/programmes/:programmeId/actualites/', {
        programmeId: '@programmeId'
      }, {
        query: {
          isArray: true,
        },
      });
    self.programmeActualite = [];
    return {
      getActualitesProgramme: () => {

        return self.programmeActualite;
      },
      query: (params) => {
        return api
          .query(params).$promise
          .then(response => {
            self.programmeActualite = response;
          })
          .catch((err) => {
            console.log('promise error', err);
          });
      },
    };
  }])
  .factory('ProgrammeActualitesMedias', ['$resource', 'Config', function (
    $resource,
    Config) {
    const self = this;
    let api = $resource(Config.API_BASE_URL +
      '/programmes/actualites/medias', {}, {
        query: {
          isArray: true,
        },
      });
    self.programmeActualiteMedias = [];
    return {
      getMediasActualitesProgramme: () => {
        return self.programmeActualiteMedias;
      },
      query: (params) => {
        return api
          .query(params).$promise
          .then(response => {
            self.programmeActualiteMedias = response;
          })
          .catch((err) => {
            console.log('promise error', err);
          });
      },
    };
  }])
  .factory('ProgrammeTimeline', ['$resource', 'Config', function ($resource,
    Config) {
    const self = this;
    let api = $resource(Config.API_BASE_URL +
      '/programmes/:programmeId/timeline/', {
        programmeId: '@programmeId'
      }, {
        query: {
          isArray: true,
        },
      });
    self.programmeTimeline = [];
    return {
      getTimelineProgramme: () => {
        return self.programmeTimeline;
      },
      query: (params) => {
        return api
          .query(params).$promise
          .then(response => {
            self.programmeTimeline = response;
          })
          .catch((err) => {
            console.log('promise error', err);
          });
      },
    };
  }])
