/**
 * Module definition and dependencies
 */
angular.module('configurateursService.Service', [
    'ngResource',
  ])

  /**
   * Service
   */
  .factory('Configurations', ['$resource', 'Config', '$rootScope', function (
    $resource,
    Config, $rootScope) {
    const self = this;
    const api = $resource(Config.IMMODESK_BASE_URL +
      '/configurateur/protected/clients/programmme/:programmeId/lot/:lotId/configurations', {
        lotId: '@lotId',
      programmeId: '@programmeId',
      }, {
        query: {
          isArray: true,
        }
      });
    self.configurations = [];
    return {
      get: () => {
        return self.configuration;
      },

      query: (params) => {
        return api
          .query(params).$promise
          .then(response => {
            self.configuration = response;
          })
          .catch((err) => {
            console.log('promise error', err);
          });
      },
    };

  }]);
