angular.module('RenewPassword.Service', [
    'ngResource'
])

/**
 * Service
 */
.factory('RenewPassword', [
    '$http', 'Config',
    function($http, Config) {
        return {
            'send': function(configParams) {
                return $http({
                    cache: false,
                    method: 'POST',
                    url: Config.IMMODESK_BASE_URL + '/public/auth/password-renew/',
                    params: configParams,
                });
            },
        };
    },
]);
