
/**
 * Module definition and dependencies
 */
angular.module('App.List', [
  'App.List.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('list', {
    parent: 'app',
    url: '/list',
    component: 'listRoute',
      data : {
          auth : true,
      }
  });
})

/**
 * Route component
 */
.component('listRoute', {
  controller: 'ListCtrl',
  templateUrl: 'list/list.html',
});
