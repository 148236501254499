angular.module('echeancesService.Service', [
    'ngResource'
  ])

  /**
   * Service
   */
  .factory('Echeance', ['$resource', 'Config', function ($resource, Config) {
    const self = this;
    const api = $resource(Config.API_BASE_ECHEANCIER +
      '/protected/echeances/transactions/:transactionId', {
        transactionId: '@transactionId'
      }, {
        query: {
          isArray: true,
        },
      });
    self.transaction = null;
    return {
      get: (params) => {
        return new api().$get(params).then(response => {
          self.transaction = response;
        }).catch((err) => {
          console.log('promise error', err);
        });
      },
      getTransaction: () => {
        return self.lot;
      },
    };
  }])
