/**
 * Module definition and dependencies
 */
angular.module('App.MesActions.Controller', [
    'contactService.Service',
    'programmesService.Service',
    'profilService.Service',
    'transactionsService.Service',
    'angular.filter',
    'membresService.Service',
  ])

  /**
   * Controller
   */
  .controller('MesActionsCtrl', ['ContactActualites', '$scope', 'Membre',
    'moment', '$rootScope', '$state',
    function (ContactActualites, $scope, Membre, moment, $rootScope, $state) {
      $scope.sortByDate = (item) => {
        let now = moment();
        return moment(item.date.date).isAfter(now);
      };
      $scope.groupByDate = (value) => {
        let now = moment();
        let i = 0;
        angular.forEach(value, (item, key) => {
          if (moment(item.date.date).isAfter(now)) {
            if( i!==0 ){
              return value[key].isAfter = 0;
            }else{
              i = 1;
                  return ;
            }
      
          }
          return value[key].isAfter = 1;
        });
      }
      $scope.$on('membreGet', () => {
        $scope.membre = Membre.getMembre();
      });
      $scope.$on('contactActualiteGet', () => {
        $scope.listActualitesContact = ContactActualites
          .getActualitesContact();
        $scope.groupByDate($scope.listActualitesContact);
      });
      this.$onInit = () => {
        if (!$rootScope.stateParamsId) {
          return $state.go('mes-projets');
        }
        $scope.listActualitesContact = ContactActualites
          .getActualitesContact();
        $scope.membre = Membre.getMembre();
        $scope.groupByDate($scope.listActualitesContact);

      }

    }
  ])
