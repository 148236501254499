
/**
 * Module definition and dependencies
 */
angular.module('App.MesProjets', [
  'App.MesProjets.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('mes-projets', {
    parent: 'app',
    url: '/mes-projets',
    component: 'mesProjetsRoute',
      data: {
          auth: true,
      },
  });
})

/**
 * Route component
 */
.component('mesProjetsRoute', {
  controller: 'MesProjetsCtrl',
  templateUrl: 'mes-projets/mes-projets.html',
});
