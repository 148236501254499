/**
 * Module definition and dependencies
 */
angular.module('App.MonProjet', [
    'App.MonProjet.Controller',
    'transactionsService.Service',
  ])

  /**
   * Config
   */
  .config($stateProvider => {
    $stateProvider.state('monProjet', {
      parent: 'app',
      url: '/mon-projet/{id: int}',
      component: 'monProjetRoute',
      data: {
        auth: true,
      }
    });
  })

  /**
   * Route component
   */
  .component('monProjetRoute', {
    controller: 'MonProjetCtrl',
    templateUrl: 'mon-projet/mon-projet.html',
  });
