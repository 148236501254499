/**
 * Module definition and dependencies
 */
angular.module('App.Timeline', [
    'App.Timeline.Controller',
  ])


  /**
   * Route component
   */
  .component('timeline', {
    controller: 'TimelineCtrl',
    templateUrl: 'timeline/timeline.html',
    bindings: {
      timeline: '<',
    }
  });
