/**
 * Module definition and dependencies
 */
angular.module('Fournisseurs.Service', [
    'ngResource',
  ])
  /**
   * Service
   */
  .factory('Fournisseurs', ['$resource', 'Config', function ($resource,
    Config) {
    const self = this;
    const fournisseurApi = $resource(Config.API_BASE_URL +
      '/fournisseur/:fournisseurId', {
        fournisseurId: '@id',
      }, {
        update: {
          method: 'PUT',
        },
        query: {
          isArray: false,
        },
      });
    fournisseurApi.prototype.createOrUpdate = () => {
      if (this.id) {
        return this.$update();
      }
      return this.$save();

    }
    self.fournisseurApi = null;
    return {
      get: (params) => {
        return new fournisseurApi().$get(params).then(response => {
          self.fournisseurApi = response;
        }).catch((err) => {
          console.log('promise error', err);
        });
      },
      getProgramme: () => {
        return self.fournisseurApi;
      },
    };
  }]);
