angular
  .module('actionsService.Service', ['ngResource'])

  /**
   * Service
   */
  .factory('Actions', [
    '$resource',
    'Config',
    function ($resource, Config) {
      const self = this;
      const api = $resource(
        Config.API_BASE_URL + '/contacts/historiques', {}, {
          query: {
            isArray: false,
          },
        }
      );
      self.historiquesContact = [];
      return {
        get: () => {
          return self.historiquesContact;
        },
        query: (params) => {
          return api
            .query(params).$promise
            .then(response => {
              self.historiquesContact = response.data;
            })
            .catch((err) => {
              console.log('promise error', err);
            });
        },
      };
    },
  ]);
