/**
 * Module definition and dependencies
 */
angular.module('App.Agenda.Controller',[
    'actionsService.Service',


])

/**
 * Controller
 */
.controller('AgendaCtrl', ['$scope','Actions', function ($scope, Actions) {
    var self = this;
    self.mesActions = function mesActions() {
        return Actions.query({}).$promise.then(function (data) {
            return self.mesActions = data;
        }).catch(function (err) {
            console.log('promise error', err);
        });
    };
    this.$onInit = function () {
        self.mesActions();
    };
}]);


