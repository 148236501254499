/**
 * Module definition and dependencies
 */
angular.module('App.Actualites', [
    'App.Actualites.Controller',
  ])

  /**
   * Config
   */
  .config($stateProvider => {
    $stateProvider.state('actualites', {
      parent: 'app',
      url: '/actualites',
      component: 'actualitesRoute',
      data: {
        auth: true,
      }
    });
  })

  /**
   * Route component
   */
  .component('actualitesRoute', {
    controller: 'ActualitesCtrl',
    templateUrl: 'actualites/actualites.html'
  });
