/**
 * Module definition and dependencies
 */
angular.module('App.Header.Component', [
    'profilService.Service',
    'App.Auth.Service',
    'membresService.Service',
    'commercialisateurs.Service',
  ])

  /**
   * Component
   */
  .component('appHeader', {
    templateUrl: 'app/header/header.html',
    controller: ('HeaderCtrl', ['$state', '$scope', 'Auth', '$stateParams',
      'Profil', 'Membre', 'Commercialisateur', '$rootScope',
      function ($state, $scope, Auth, $stateParams, Profil, Membre,
        Commercialisateur, $rootScope) {
        const self = this;
        self.membre = '';
        this.translateCurrentPage = (name) => {

          let tabCurrent = {
            'monProjet': 'Projet',
            'documents': 'Documents',
            'mes-actualites': 'Actualités',
            'mon-actualite': 'Actualité',
            'mes-actions': 'Actions',
            'compte': 'Profil',
            'monInvestissement': 'Accueil',
            'mes-projets': 'Mes investissements',
            'monLot': 'Projet',
            'messages': 'Messagerie',
            'reset-password': 'Compte',
            'configurations': 'Configurations',
            'budget': 'Appels de fond',
          }
          return tabCurrent[name];
        }


        $scope.$watch(() => {

          return self.currentPage = this.translateCurrentPage($state
            .current.name);
        });
        $scope.$on('profilGet', () => {
          self.profil = Profil.getProfil();
          Membre.get({
            membreId: self.profil.interlocuteurs_ids[0]
          }).then(() => {
            self.membre = Membre.getMembre();
          });
          Commercialisateur.get({
            commercialisateurId: self.profil.commercialisateur_id,
          }).then(() => {
            self.commercialisateur = Commercialisateur
              .getCommercialisateur();
          });
        });

        this.$onInit = () => {
          self.profil = Profil.getProfil();
          $scope.currentTransactionId = $stateParams.id;
        }
        this.logout = () => {

          Auth.logout(false, {
            promoteur: $rootScope.promoteur,
          });
        };

      }
    ]),
  });
