
/**
 * Module definition and dependencies
 */
angular.module('App.Budget', [
  'App.Budget.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('budget', {
    parent: 'app',
    url: '/budget',
    component: 'budgetRoute',
      data : {
          auth : true,
      }
  });
})

/**
 * Route component
 */
.component('budgetRoute', {
  controller: 'BudgetCtrl',
  templateUrl: 'budget/budget.html',
})
.component('budgetTransaction', {
  controller: 'BudgetTransactionCtrl',
  templateUrl: 'budget/budget-transaction.html',
  bindings: {
    transactionId: '<',
    statut: '<',
  },
});