/**
 * Module definition and dependencies
 */
angular.module('App.MonCompte', [
    'App.MonCompte.Controller',
  ])

  /**
   * Config
   */
  .config($stateProvider => {
    $stateProvider.state('compte', {
      parent: 'app',
      url: '/mon-compte',
      component: 'compteRoute',
      data: {
        auth: true,
      }
    });
  })

  .component('compteRoute', {
    controller: 'MonCompteCtrl',
    templateUrl: 'mon-compte/mon-compte.html',
  });
