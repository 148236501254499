/**
 * Module definition and dependencies
 */
angular.module('contactService.Service', [
    'ngResource',
  ])

  /**
   * Service
   */
  .factory('ContactMessage', ['$resource', 'Config', function ($resource,
    Config) {
    let api = $resource(Config.API_BASE_URL + '/contacts/messages', {}, {
      update: {
        method: 'PUT',
      },
      query: {
        isArray: false,
      },
    });
    return api;
  }])
  .factory('ContactActualites', ['$resource', 'Config', '$rootScope', function (
    $resource,
    Config, $rootScope) {
    const self = this;
    const api = $resource(Config.API_BASE_URL +
      '/contacts/:contactId/actualites/', {
        contactId: '@contactId'
      }, {
        update: {
          method: 'PUT',
        },
        query: {
          isArray: true,
        },
      });
    self.listActualitesContact = [];

    return {
      getActualitesContact: () => {
        return self.listActualitesContact;
      },
      query: (params) => {
        return api
          .query(params).$promise
          .then(response => {
            self.listActualitesContact = response;
            $rootScope.$broadcast('contactActualiteGet');
          })
          .catch((err) => {
            console.log('promise error', err);
          });
      },
    };
  }]);
