/**
 * Module definition and dependencies
 */
angular.module('App.MonProjet.Controller', [
    'transactionsService.Service',
  ])

  /**
   * Controller
   */
  .controller('MonProjetCtrl', ['Transactions', '$scope', '$stateParams',
    '$rootScope',
    function (Transactions, $scope, $stateParams, $rootScope) {
      let self = this;
      $rootScope.stateParamsId = $stateParams.id;
      $scope.$watch('stateParamsId', () => {
        $scope.transaction = Transactions.getTransactionById(
          $rootScope.stateParamsId);
        return $rootScope.stateParamsId;
      });
      $scope.$on('transactionsGet', () => {
        $scope.transaction = Transactions.getTransactionById(
          $rootScope.stateParamsId);
      });
      self.$onInit = () => {
        $scope.transaction = Transactions.getTransactionById($rootScope
          .stateParamsId);
      };

    },
  ]);
