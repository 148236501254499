angular.module('configsService.Service', [
    'ngResource',
  ])

  .factory('ConfigsDocuments', [
    '$http', 'Config',
    function ($http, Config) {
      return {
        'getCategories': function (configParams) {
          return $http({
            method: 'GET',
            url: Config.API_BASE_URL + '/configs/documents/categories',
            params: configParams,
          });
        },
      };
    },
  ]);
