/**
 * Module definition and dependencies
 */
angular.module('App.Configurations.Controller', [
    'ngResource',
    'transactionsService.Service',
    'App.Auth.Service',
  ])

  /**
   * Controller
   */
  .controller('ConfigurationsCtrl', ['Transactions', '$scope', 'Config',
    'Auth',
    function (Transactions, $scope, Config, Auth) {
      let self = this;
      $scope.$on('configurationsGet', () => {
        $scope.listTransactions = Transactions
          .getTransactions();
        self.getConfigurations($scope.listTransactions);
      });
      self.configurations = [];
      self.getConfigurations = (transactions) => {
        angular.forEach(transactions, item => {
          angular.forEach(item.configurations, configuration => {
            configuration.href = Config.CONFIGURATEUR_URL +
              '/#!/programme/' + item
              .programme_id + '/lot/' + item.lot_id +
              '/configuration/' + configuration.id +
              '/configurateur?token=' + Auth.token.accessToken;
            configuration.programme_nom = item.programme_nom;
            configuration.lot_nom = item.lot_nom;
            self.configurations.push(configuration);
          });
        });
      }
      self.$onInit = () => {
        $scope.listTransactions = Transactions
          .getTransactions();
        self.getConfigurations($scope.listTransactions);
      };

    }
  ]);
