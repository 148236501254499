/**
 * Module definition and dependencies
 */
angular.module('documentsService.Service', [
    'ngResource',
  ])

  /**
   * Service
   */
  .factory('DocumentsLot', ['$resource', 'Config', function ($resource,
    Config) {
    const api = $resource(Config.API_BASE_URL +
      '/clients/documents?type=lot', {}, {
        query: {
          isArray: false,
        },
      });
    this.documentsLot = [];
    return {
      getDocumentsLot: () => {
        return this.documentsLot;
      },
      query: (params) => {
        return api
          .query(params).$promise
          .then(response => {
            this.documentsLot = response.data;
          })
          .catch((err) => {
            console.log('promise error', err);
          });
      },
    }
  }])
  .factory('DocumentsProgramme', ['$resource', 'Config', function ($resource,
    Config) {
    const api = $resource(Config.API_BASE_URL +
      '/clients/documents?type=programme', {}, {
        query: {
          isArray: false,
        },
      });
    this.documentsProgramme = [];
    return {
      getDocumentsProgramme: () => {
        return this.documentsProgramme;
      },
      query: (params) => {
        return api
          .query(params).$promise
          .then(response => {
            this.documentsProgramme = response.data;
          })
          .catch((err) => {
            console.log('promise error', err);
          });
      },
    }
  }])
  .factory('DocumentsContact', ['$resource', 'Config', '$rootScope', function (
    $resource, Config, $rootScope) {
    const api = $resource(Config.API_BASE_URL + '/documents/contacts', {}, {
      query: {
        isArray: false,
      },
    });
    this.documentsContact = [];
    return {
      getDocumentsContact: () => {
        return this.documentsContact;
      },
      query: (params) => {
        return api
          .query(params).$promise
          .then(response => {
            this.documentsContact = response.data;

            $rootScope.$broadcast('documentsContactGet');
          })
          .catch((err) => {
            console.log('promise error', err);
          });
      },
    }

  }])
  .factory('DocumentsDemandes', ['$resource', '$rootScope', 'Config', function (
    $resource, $rootScope,
    Config) {
    const api = $resource(Config.API_BASE_URL +
      '/clients/documents/demandes/', {
        limit: -1
      }, {
        query: {
          isArray: false,
        },
      });
    this.documentsDemande = [];
    return {
      getDocumentsDemandes: () => {
        return this.documentsDemande;
      },
      query: (params) => {
        return api
          .query(params).$promise
          .then(response => {
            this.documentsDemande = response.data;
            $rootScope.documentDemandes = this.documentsDemande.length;
            $rootScope.$broadcast('documentsDemandeGet');
          })
          .catch((err) => {
            console.log('promise error', err);
          });
      },
    }
  }])
  .factory('Documents', ['$resource', 'Config', function ($resource, Config) {
    var api = $resource(Config.API_BASE_URL + '/clients/documents/:id', {
      id: '@id'
    }, {
      query: {
        isArray: false,
      },
    });
    return api;
  }]);
