/**
 * Module definition and dependencies
 */
angular.module('App.Auth', [
    'App.Auth.Service',
    'App.Auth.Token.Model',
    'App.Auth.AuthInterceptor.Service',
    'Login.Service',
    'App.Auth.Controller',
    'ResetPassword.Service',
    'RenewPassword.Service',
  ])

  /**
   * Config
   */
  .config(($httpProvider, TokenProvider, Config) => {

    //Add auth interceptor (must be before the error interceptor)
    $httpProvider.interceptors.unshift('AuthInterceptor');

    //Configure token provider
    TokenProvider.setClientId(Config.AUTH_CLIENT_IDENTIFIER);
  })

  /**
   * Run logic
   */
  .run(($interval, $state, $log, $transitions, Auth) => {

    /**
     * Check token expiration
     */
    $interval(() => {
      if (Auth.isAuthenticated() && Auth.isExpired()) {
        Auth.refresh().catch(() => Auth.logout(true));

      }
    }, 10000);

    /**
     * Authentication guard
     */
    $transitions.onBefore({
      to(state) {
        return (state.data && state.data.auth === true);
      },
    }, transition => {

      //Get roles
      const to = transition.to();
      const roles = to.data.roles;
      const target = transition.targetState();

      //Not authenticated?
      if (!Auth.isAuthenticated()) {
        $log.info('State', to.name, 'requires authentication.');
        return Auth.goToLoginState().$promise.then(() => {}).catch((
          err) => {
          console.log('promise error', err);
        });
      }

      //Specific roles only?
      if (roles && !roles.some(role => Auth.hasRole(role))) {
        $log.info('State', to.name, 'is role restricted.');
        return transition.router.stateService.target('home');
      }
    });

    /**
     * Remember last successful transition
     */
    $transitions.onSuccess({}, transition => {
      Auth.setLastState(transition.targetState());
    });

  })

  .config($stateProvider => {
    $stateProvider.state('auth', {
      url: '',
      virtual: true,
      component: 'authRoute'
    });
  })
  .component('authRoute', {
    templateUrl: 'auth/auth.html'
  })
  .config($stateProvider => {
    $stateProvider.state('auth.login', {
      url: '/login',
      params: {
        promoteur: null,
      },
      component: 'loginRoute'
    });
  })
  .component('loginRoute', {
    controller: 'LoginRouteCtrl',
    templateUrl: 'auth/login.html'
  })

  .config($stateProvider => {
    $stateProvider.state('renewPassword', {
      parent: 'auth',
      url: '/renew-password',
      params: {
        promoteur: null,
      },
      component: 'renewPasswordRoute'
    });
  })
  .component('renewPasswordRoute', {
    controller: 'RenewPasswordRouteCtrl',
    templateUrl: 'auth/renewPassword.html'
  })
  .config($stateProvider => {
    $stateProvider.state('resetPassword', {
      parent: 'auth',
      url: '/reset-password/:token',
      component: 'resetPasswordRoute',
      resolve: {
        user: ['ResetPassword', '$stateParams', (ResetPassword,
          $stateParams) => {
          return ResetPassword.retrieveTokenInformation($stateParams
            .token);
        }]
      }
    });
  })
  .component('resetPasswordRoute', {
    controller: 'ResetPasswordRouteCtrl',
    templateUrl: 'auth/resetPassword.html',
    bindings: {
      'user': '<'
    }
  })
  .config($stateProvider => {
    $stateProvider.state('activation', {
      parent: 'auth',
      url: '/activation/:token',
      component: 'activationRoute',
      resolve: {
        user: ['ActivationPassword', '$stateParams', (ActivationPassword,
          $stateParams) => {
          return ActivationPassword.retrieveTokenInformation(
            $stateParams.token);
        }]
      }
    });
  })
  .component('activationRoute', {
    controller: 'ActivationCtrl',
    templateUrl: 'auth/activation.html',
    bindings: {
      'user': '<'
    }
  });
