/**
 * Module definition and dependencies
 */
angular.module('membresService.Service', [
    'ngResource',
  ])

  /**
   * Service
   */
  .factory('Membre', ['$resource', 'Config', '$rootScope', function ($resource,
    Config, $rootScope) {
    const self = this;
    const membreApi = $resource(Config.API_BASE_URL +
      '/membres/:membreId', {
        membreId: '@membreId',
      }, {
        update: {
          method: 'PUT',
        },
        query: {
          isArray: false,
        },
      });

    self.membre = null;
    return {
      get: (params) => {
        return new membreApi().$get(params).then(response => {
          self.membre = response;
          $rootScope.$broadcast('membreGet');
        }).catch((err) => {
          console.log('promise error', err);
        });
      },
      getMembre: () => {
        return self.membre;

      },
    };

  }])
