/**
 * Module definition and dependencies
 */
angular.module('lotsService.Service', [
    'ngResource',
  ])

  /**
   * Service
   */
  .factory('Lots', ['$resource', 'Config', function ($resource, Config) {
    const self = this;
    const lotsApi = $resource(Config.API_BASE_URL + '/lots/', {
      membreId: '@membreId',
    }, {
      query: {
        isArray: false,
      }
    });
    self.listLots = [];
    return {
      get: () => {
        return self.listLots;
      },
      query: (params) => {
        return lotsApi
          .query(params).$promise
          .then(response => {
            self.listLots = response.data;
          })
          .catch((err) => {
            console.log('promise error', err);
          });
      },
    };
  }])
  .factory('Lot', ['$resource', 'Config', '$rootScope', function ($resource,
    Config, $rootScope) {
    const self = this;
    const lotApi = $resource(Config.API_BASE_URL +
      '/lots/:lotId?lots_annexes=1', {
        lotId: '@lotId',
      }, {
        query: {
          isArray: false,
        }
      });
    self.lot = null;
    return {
      get: (params) => {
        return new lotApi().$get(params).then(response => {
          self.lot = response;
          $rootScope.$broadcast('lotGet');
        }).catch((err) => {
          console.log('promise error', err);
        });
      },
      getLot: () => {
        return self.lot;
      },
    };
  }])
  .factory('LotDetails', ['$resource', 'Config', function ($resource, Config) {
    const self = this;
    const lotsDetails = $resource(Config.API_BASE_URL + Config
      .API_COM_BASE_URL +
      '/lots/:lotId/details', {
        lotId: '@lotId'
      }, {
        query: {
          isArray: false,
        }
      });
    self.lotDetails = null;
    return {
      get: (params) => {
        return new lotsDetails().$get(params).then(response => {
          self.lotDetails = response;
        }).catch((err) => {
          console.log('promise error', err);
        });
      },
      getLotDetails: () => {
        return self.lotDetails;
      },
    };
  }])
