/**
 * Module definition and dependencies
 */
angular.module('App.MesActualites', [
    'App.MesActualites.Controller',
    'slickCarousel'
  ])
  .config($stateProvider => {
    $stateProvider.state('mes-actualites', {
      parent: 'app',
      url: '/mes-actualites',
      component: 'mesActualites',
      data: {
        auth: true,
      },
    });

  })
  .config($stateProvider => {
    $stateProvider.state('mon-actualite', {
      parent: 'app',
      url: '/mes-actualites/{actualiteId: int}',
      component: 'monActualite',
      data: {
        auth: true,
      },
    });

  })

  /**
   * Route component
   */
  .component('mesActualites', {
    controller: 'MesActualitesCtrl',
    templateUrl: 'mes-actualites/mes-actualites.html',
  })
  .component('monActualiteHome', {
    controller: 'MesActualitesCtrl',
    templateUrl: 'mes-actualites/mon-actualite-home.html',
  })
  .component('monActualite', {
    controller: 'MesActualitesCtrl',
    templateUrl: 'mes-actualites/mon-actualite.html',
  })
