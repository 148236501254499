angular.module('ResetPassword.Service', [])
  .factory('ResetPassword', ['Config', '$http', function (Config, $http) {
    return {
      retrieveTokenInformation: function (token) {
        return $http.get(Config.IMMODESK_BASE_URL + '/public/auth/password-reset?code=' + token, {}).then(res => res.data)
      },
      resetPassword: function (userId, token, password) {
        return $http.post(Config.IMMODESK_BASE_URL + '/public/auth/password-reset', {
          'user_id': userId,
          'token': token,
          'password': password,
        });
      }
    }
  }])

    .factory('ActivationPassword', ['Config', '$http', function (Config, $http) {
        return {
            retrieveTokenInformation: function (token) {
                return $http.get(Config.IMMODESK_BASE_URL + '/public/contacts/activation?code=' + token, {}).then(res => res.data)
            },
            activationPassword :function (userId, token, password) {
                return $http.post(Config.IMMODESK_BASE_URL + '/public/contacts/activation', {
                    'contact_id': userId,
                    'token': token,
                    'password': password,
                });
            }
        }
    }]);