/**
 * Module definition and dependencies
 */
angular.module('App.ResetPassword.Controller', [
    'profilService.Service',
    'ngFileUpload',
  ])

  /**
   * Controller
   */
  .controller('ResetPasswordCtrl', ['Profil',
    function (Profil) {
      const self = this;
      self.valider = () => {
        return Profil.update(self.password);
      };

      self.checkPassword = () => {
        self.formPasswordDisabled = true;
        self.passwordMatch = false;
        if (self.password && self.password.password && self.password
          .password_validation) {
          if (self.password.password === self.password
            .password_validation) {
            self.passwordMatch = 'match';
            if (self.password.password_old) {
              self.formPasswordDisabled = false;
            }
          } else {
            self.passwordMatch = 'nomatch';
          }
        }
      }
    },
  ]);
