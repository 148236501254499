angular.module('App.Mock',['ngMockE2E'])
    .run(function($httpBackend){
        const contacts = [
            {
                id: 1,
                name: 'Ada Lovelace',
                phone: '8445551815'
            },
            {
                id: 2,
                name: 'Grace Hopper',
                phone: '8445551906'
            },
            {
                id: 3,
                name: 'Charles Babbage',
                phone: '8445556433'
            }
        ];

        function findContactById(id) {
            // Convert id to a number.
            var contactId = Number(id);

            var matches = contacts.filter(function(contact) {
                return contact.id === contactId;
            });

            var contact = matches.shift();

            return contact;
        }

// Query; returns all contacts.
        $httpBackend.whenGET('/contacts').respond(contacts);

// Save; create a new contact.
        $httpBackend.whenPOST('/contacts').respond(function(method, url, data) {
            var newContact = angular.fromJson(data);
            contacts.push(newContact);

            return [200, newContact, {}];
        });

// Get; return a single contact.
        $httpBackend.whenGET(/\/contacts\/(\d+)/, undefined, ['id']).respond(function(method, url, data, headers, params) {
            var contact = findContactById(params.id);

            if (contact == null) {
                return [404, undefined, {}];
            }

            return [200, contact, {}];
        });

// Update; change details for an existing contact.
        $httpBackend.whenPUT(/\/contacts\/(\d+)/, undefined, undefined, ['id']).respond(function(method, url, data, headers, params) {
            var contact = findContactById(params.id),
                parsedData = angular.fromJson(data);

            if (contact == null) {
                return [404, undefined, {}];
            }

            angular.extend(contact, parsedData);

            return [200, contact, {}];
        });

// Delete; remove existing contact.
        $httpBackend.whenDELETE(/\/contacts\/(\d+)/, undefined, ['id']).respond(function(method, url, data, headers, params) {
            var contact = findContactById(params.id);

            if (contact == null) {
                return [404, undefined, {}];
            }

            // Replace contacts array with filtered results, removing deleted contact.
            contacts.splice(contacts.indexOf(contact), 1);

            return [200, undefined, {}];
        });
    })
;
