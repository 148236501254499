/**
 * Module definition and dependencies
 */
angular.module('App.MonLot.Controller', [
    'transactionsService.Service',
    'ui.bootstrap',
    'ngAnimate',
  ])

  /**
   * Controller
   */
  .controller('MonLotCtrl', ['Transactions', '$scope', '$rootScope',
    '$stateParams', '$interval',
    function (Transactions, $scope, $rootScope, $stateParams, $interval) {
      $scope.myInterval = 5000;
      $scope.noWrapSlides = false;
      $scope.active = 0;
      $rootScope.stateParamsId = $stateParams.id;

      $scope.$watch('stateParamsId', () => {

        $scope.transaction = Transactions.getTransactionById($rootScope
          .stateParamsId);
        return $rootScope.stateParamsId;
      });
      $scope.$on('transactionsGet', () => {
        $scope.transaction = Transactions.getTransactionById(
          $rootScope.stateParamsId);
      });
      $scope.slickConfig = {
        enabled: true,
        autoplay: true,
        draggable: false,
        autoplaySpeed: 5000,
        method: {},
        event: {
          afterChange: function (event, slick, currentSlide, nextSlide) {
            $scope.active = currentSlide; // save current index each time
          }
        }
      };

      this.$onInit = () => {
        $scope.transaction = Transactions.getTransactionById($rootScope
          .stateParamsId);
      };

    }
  ])
