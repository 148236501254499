angular.module('App.scrollDirective', [])
  .directive('scroll', () => {
    return (scope) => {
      if (scope.$last) {
        let myInterval = window.setInterval(() => {
          let elem = document.getElementById('msg-history');
          elem.scrollTop = elem.scrollHeight;
          foo();
        }, 1000);
        let foo = () => {
          clearInterval(myInterval);
        };
      }
    };
  });
