/**
 * Module definition and dependencies
 */
angular.module('App.Sidebar.Component', ['membresService.Service', 'ngMap',
    'configsService.Service'
  ])

  /**
   * Component
   */
  .component('appSidebar', {
    templateUrl: 'app/sidebar/sidebar.html',
    controller: ('SidebarCtrl', ['$stateParams', '$scope', 'Membre', 'NgMap',
      'Config',
      function ($stateParams, $scope, Membre, NgMap, Config) {
        $scope.lat = '';
        $scope.lng = '';
        $scope.$on('membreGet', () => {
          $scope.membre = Membre.getMembre();
        });
        $scope.currentTransactionId = $stateParams.id;
        $scope.$watch('currentTransactionId', () => {
          return $scope.currentTransactionId;
        });
        this.$onInit = () => {
          $scope.membre = Membre.getMembre();
          $scope.googleMapsUrl =
            "https://maps.googleapis.com/maps/api/js?key=" + Config
            .GOOGLE_MAP_KEY;
        }

      },
    ]),
  });
