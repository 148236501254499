/**
 * Module definition and dependencies
 */
angular.module('App.ResetPassword', [
    'App.ResetPassword.Controller',
  ])

  /**
   * Config
   */
  .config($stateProvider => {
    $stateProvider.state('reset-password', {
      parent: 'app',
      url: '/reset-password',
      component: 'resetPassword',
      data: {
        auth: true,
      },
    });
  })

  .component('resetPassword', {
    controller: 'ResetPasswordCtrl',
    templateUrl: 'reset-password/reset-password.html',
  })
