/**
 * Module definition and dependencies
 */
angular.module('App.Documents', [
    'App.Documents.Controller',
  ])

  /**
   * Config
   */
  .config($stateProvider => {
    $stateProvider.state('documents', {
      parent: 'app',
      url: '/documents',
      component: 'documentsRoute',
      params: {
        programmeId: null,
        lotId: null,
      },
      data: {
        auth: true,
      }
    });

  })

  /**
   * Route component
   */
  .component('documentsRoute', {
    controller: 'DocumentsCtrl',
    templateUrl: 'documents/documents.html',
    bindings: {
      programmeId: '<',
      lotId: '<',
    }
  })
  .component('listDocuments', {
    controller: 'DocumentsCtrl',
    templateUrl: 'documents/listDocuments.html',
    bindings: {
      programmeId: '<',
      lotId: '<',
    }
  })
  .component('listDocumentsProgramme', {
    controller: 'DocumentsProgrammeCtrl',
    templateUrl: 'documents/listDocumentsProgramme.html',
    bindings: {
      documentsProgramme: '<',
    }
  })
  .component('listDocumentsLot', {
    controller: 'DocumentsLotCtrl',
    templateUrl: 'documents/listDocumentsLot.html',
    bindings: {
      documentsLot: '<',
    }
  })
  .component('listDocumentsDemandes', {
    controller: 'ListDocumentsDemandesCtrl',
    templateUrl: 'documents/listDocumentsDemandes.html',
    bindings: {
      listDocumentsDemandes: '=',
    },
  })
  .component('uploadDocumentsBtn', {
    controller: 'UploadDocumentsCtrl',
    templateUrl: 'documents/uploadDocumentsBtn.html',
    bindings: {
      documents: '=',
      title: '<',
      maxSize: '<',
      pattern: '<',
      useBase64: '<',
      totalSize: '=',
    },
  });
