/**
 * Module definition and dependencies
 */
angular.module('messageService.Service', [
    'ngResource',
    'toastr',
  ])

  /**
   * Service
   */
  .factory('Message', ['$resource', 'Config', 'toastr', function ($resource,
    Config, toastr) {
    const self = this;
    const api = $resource(Config.API_BASE_URL + '/contacts/messages/', {}, {
      update: {
        method: 'PUT',
      },
      query: {
        isArray: false,
      },
    });
    self.contactMessages = [];

    return {
      save: params => {
        return new api(params).$save().then(() => {
          toastr.success('Message envoyé', 'Succès');
          return true;
        }).catch((err) => {
          toastr.error(err, 'Erreur');
        });;
      },
      get: () => {
        return self.contactMessages;
      },
      query: () => {
        return api
          .query().$promise
          .then(response => {
            self.contactMessages = response.data;
          })
          .catch((err) => {
            console.log('promise error', err);
          });
      },
    };
  }]);
