/**
 * Module definition and dependencies
 */
angular.module('profilService.Service', [
    'ngResource',
    'toastr',
  ])

  /**
   * Service
   */
  .factory('Profil', ['$resource', 'Config', '$rootScope', 'toastr', function (
    $resource,
    Config, $rootScope, toastr) {
    const self = this;
    const api = $resource(Config.API_BASE_URL + '/profil/', {}, {
      update: {
        method: 'PUT',
      },
      query: {
        isArray: false,
      },
    });
    api.prototype.createOrUpdate = () => {
      if (this.id) {
        return this.$update();
      }
      return this.$save();
    };

    self.profil = null;

    return {
      update: (params) => {
        return new api().$update(params).then(() => {
          toastr.success('Mot de passe modifié', 'Succès');
        }).catch((err) => {
          toastr.error(err, 'Erreur');
        });
      },
      get: () => {
        return new api().$get().then(response => {
          self.profil = response;
          $rootScope.$broadcast('profilGet');
        }).catch((err) => {
          console.log('promise error', err);
        });
      },
      getProfil: () => {
        return self.profil;
      },
    };
  }]);
