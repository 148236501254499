
/**
 * Module definition and dependencies
 */
angular.module('App.MonContact', [
  'App.MonContact.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
    $stateProvider.state('contact', {
        parent: 'app',
        url: '/mon-contact',
        component: 'contact',
        data : {
            auth : true,
        }
    });
})
.component('contactRoute', {
    controller: 'MonContactCtrl',
    templateUrl: 'mon-contact/mon-contact.html',
})