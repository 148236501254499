angular
  .module('App.Auth.Controller', ['ui.bootstrap', 'toastr'])
  .controller('LoginRouteCtrl', [
    'Login',
    '$state',
    'Auth',
    '$location',
    '$stateParams',
    '$storage',
    '$rootScope',
    'toastr',
    function (Login, $state, Auth, $location, $stateParams, $storage,
      $rootScope,
      toastr) {
      const self = this;
      self.promoteur = '';
      self.hideLinkRenewpassword = true;

      function getParameterByName(name, url) {
        if (!url) {
          url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
        if (!results) {
          return null;
        }
        if (!results[2]) {
          return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
      }

      self.$onInit = () => {
        if (Auth.isAuthenticated()) {
          $state.go('mesProjets');
        }
        if ($stateParams.promoteur) {
          self.promoteur = $stateParams.promoteur;
          $location.search('promoteur', self.promoteur);
          self.hideLinkRenewpassword = false;
        } else {
          let params = $location.search();
          if (params && params.promoteur) {
            self.promoteur = params.promoteur;
            self.hideLinkRenewpassword = false;
          }
        }
        $rootScope.promoteur = self.promoteur;
        $storage.local.set('promoteur', self.promoteur);
        $rootScope.cheminThemePromoteurCss =
          'themes-promoteurs/theme-' + self.promoteur + '.css';
      };

      self.login = () => {
        Login.setPromoteurId(self.promoteur);
        if (self.user.email && self.user.password) {
          Login.login(self.user.email, self.user.password).then(
            () => {
              Auth.loginWithCredentials(self.user);
              toastr.success('Authentification réussie', 'Succès');
            },
            err => {
              toastr.error(err.message, 'Erreur');
            }
          );
        } else {
          toastr.error('Merci de renseigner tous les champs', 'Erreur');
        }
      };
    },
  ])
  .controller('RenewPasswordRouteCtrl', [
    'RenewPassword',
    '$stateParams',
    '$state',
    '$storage',
    '$rootScope',
    'toastr',
    function (
      RenewPassword,
      $stateParams,
      $state,
      $storage,
      $rootScope,
      toastr
    ) {
      const self = this;
      self.message = '';
      self.$onInit = () => {
        if ($stateParams.promoteur) {
          $storage.local.set('promoteur', $stateParams.promoteur);
          $rootScope.cheminThemePromoteurCss =
            'themes-promoteurs/theme-' + $stateParams.promoteur + '.css';
        } else {
          $state.go('auth.login');
        }
      };
      self.renewPassword = () => {
        if (self.user.email) {
          RenewPassword.send({
            email: self.user.email,
            promoteur: $stateParams.promoteur,
            discr: "contact",
            origine: window.location.origin + window.location.pathname,
          }).then(
            () => {
              toastr.info(
                'Si un compte est associé à l\'adresse e-mail saisie, vous allez recevoir les instructions pour réinitialiser votre mot de passe.',
                'Information', {
                  timeOut: 9999,
                }
              );
            },
            () => {
              toastr.error('Une erreur s\'est produite', 'Erreur');
            }
          );
        }
      };
    },
  ])
  .controller('ResetPasswordRouteCtrl', [
    '$stateParams',
    'ResetPassword',
    '$location',
    '$rootScope',
    '$storage',
    'toastr',
    '$state',
    function ($stateParams, ResetPassword, $location, $rootScope, $storage,
      toastr, $state) {
      this.redirection = () => {
        toastr.info(
          'Vous allez être redirigé vers la page d\'authentification',
          'Information', {
            timeOut: 6000,
            onHidden: $state.go('auth.login', {
              promoteur: this.user.promoteur,
            }),
          }
        );
      };
      this.$onInit = () => {
        const promoteurUrl = $location.search().promoteur;
        if (promoteurUrl) {
          $storage.local.set('promoteur', promoteurUrl);
          $rootScope.promoteur = promoteurUrl;
          $rootScope.cheminThemePromoteurCss =
            'themes-promoteurs/theme-' + $rootScope.promoteur + '.css';
        }
      };
      this.resetPassword = () => {
        this.loader = true;
        let token = $stateParams.token;
        if (this.user.password === this.user.passwordConfirmation) {
          ResetPassword.resetPassword(
            this.user.id,
            token,
            this.user.password
          ).then(
            () => {
              toastr.success('Votre mot de passe a été modifié',
                'Succès', {
                  onHidden: this.redirection(),
                });
            },
            err => {
              toastr.error(err.message, 'Erreur');
            }
          );
        } else {
          toastr.error(
            'Erreur lors de la confirmation du mot de passe',
            'Erreur'
          );
        }
      };
    },
  ])
  .controller('ActivationCtrl', [
    '$stateParams',
    'ActivationPassword',
    'toastr',
    '$rootScope',
    '$location',
    '$storage',
    function (
      $stateParams,
      ActivationPassword,
      toastr,
      $rootScope,
      $location,
      $storage
    ) {
      this.msg = '';
      this.$onInit = () => {
        const promoteurUrl = $location.search().promoteur;
        if (promoteurUrl) {
          $storage.local.set('promoteur', promoteurUrl);
          $rootScope.promoteur = promoteurUrl;
          $rootScope.cheminThemePromoteurCss =
            'themes-promoteurs/theme-' + $rootScope.promoteur + '.css';
        }
      };
      this.activationPassword = () => {
        let token = $stateParams.token;
        if (this.user.password === this.user.passwordConfirmation) {
          ActivationPassword.activationPassword(
            this.user.id,
            token,
            this.user.password
          ).then(
            () => {
              this.msg =
                'Votre compte a bien été activé, vous pouvez désormais vous connecter. Vous allez recevoir un email de confirmation';
            },
            err => {
              toastr.error(err.message, 'Erreur');
            }
          );
        } else {
          toastr.error(
            'Erreur lors de la confirmation du mot de passe',
            'Erreur'
          );
        }
      };
    },
  ]);
