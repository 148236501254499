/**
 * Module definition and dependencies
 */
angular.module('App.MonCompte.Controller', [
    'membresService.Service',
    'ngFileUpload',
    'toastr',
  ])

  /**
   * Controller
   */
  .controller('MonCompteCtrl', ['Upload', 'Profil', '$scope', 'toastr',
    function (Upload, Profil, $scope, toastr) {
      const self = this;
      $scope.$on('profilGet', () => {
        this.profil = Profil.getProfil();
      });
      self.$onInit = () => {
        self.profil = Profil.getProfil();
      };
      self.uploadFiles = (file, errFiles) => {
        self.errFile = errFiles && errFiles[0];
        if (file) {
          self.file = file;
          file.upload = Upload.base64DataUrl(file).then((urls) => {
            self.profil.image = urls;
          });
        }
      };
      self.save = () => {
        return self.profil.$update().then(() => {
          toastr.success('Vos informations ont été mises à jour.',
            'Succès');
        }).catch((err) => {
          toastr.error(err, 'Erreur');
        });
      }
    },
  ]);
