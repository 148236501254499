/**
 * Module definition and dependencies
 */
angular.module('App.Actualites.Controller', [
    'programmesService.Service'
])

/**
 * Controller
 */
.controller('ActualitesCtrl',['AlertesProgrammes', function (AlertesProgrammes) {


    var self = this;
    self.mesAlertes = function mesActions() {
        return AlertesProgrammes.query({}).$promise.then(function (data) {
            return self.mesAlertes = data.data;
        }).catch(function (err) {
            console.log('promise error', err);
        });
    };
    this.$onInit = function () {
        self.mesAlertes();
    };
}]);


