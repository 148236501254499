/**
 * Module definition and dependencies
 */
angular.module('App.Mail', [
    'App.Mail.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
    $stateProvider.state('mail', {
        parent: 'app',
        url: '/mail',
        component: 'mailRoute',
        data : {
            auth : true,
        }
    });
})

/**
 * Route component
 */
.component('mailRoute', {
    controller: 'MailCtrl',
    templateUrl: 'mail/mail.html',
    bindings: {
        interlocuteurId : '<',
        modalInstance : '<',
    }
});
