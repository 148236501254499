/**
 * Module definition and dependencies
 */
angular.module('App', [

  //Angular & 3rd party
  'ui.router',

  //Meanie modules
  'Api.Service',
  'Analytics.Service',
  'Convert.Service',
  'DuplicateRequestsFilter.Decorator',
  'FormControls.Component',
  'KeyCodes.Service',
  'Log.Service',
  'Storage.Service',
  'Store.Service',
  'ngSanitize',
  'angular-loading-bar',

  //Core modules
  'App.scrollDirective',
  'App.imageOnLoadDirective',
  'App.Config',
  'App.Home',
  'App.Documents',
  'App.Configurations',
  'App.Templates',
  'App.Component',
  'App.Agenda',
  'App.MonCompte',
  'App.MonContact',
  'App.Mail',
  'App.Budget',
  'App.MonProjet',
  'App.MesProjets',
  'App.Messages',
  'App.Actualites',
  'App.Auth',
  'App.Error',
  'App.MesActualites',
  'App.MonLot',
  'App.ResetPassword',
  'App.MesActions',
  'App.Timeline',
  'App.InfosLot',
  //Shared services
  'Shared.Moment.Service',

  //Shared filters
  'Shared.Moment.Filter',
  'Bytes.Filter',
]);
