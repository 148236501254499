/**
 * Module definition and dependencies
 */
angular.module('App.List.Controller', [])

  /**
   * Controller
   */
  .controller('ListCtrl', function (moment, $rootScope) {

    /**
     * On init
     */
    $rootScope.pagename = 'Liste';
    this.$onInit = function () {
      //Set date
      this.now = moment();
    };
  });
