/**
 * Module definition and dependencies
 */
angular.module('App.Documents.Controller', [
    'documentsService.Service',
    'transactionsService.Service',
    'configsService.Service',
    'App.Auth.Service',
    'ngFileUpload',
  ])

  /**
   * Controller
   */
  .controller('DocumentsCtrl', ['$scope', 'DocumentsContact',
    'Auth', '$uibModal', '$rootScope', '$state',
    function ($scope, DocumentsContact,
      Auth, $uibModal, $rootScope, $state) {
      const self = this;
      self.limit = 999;
      $scope.$on('documentsContactGet', () => {
        self.mesDocumentsContact = DocumentsContact
          .getDocumentsContact();
      });

      self.$onInit = () => {
        if (!$rootScope.stateParamsId) {
          return $state.go('mes-projets');
        }
        self.mesDocumentsContact = DocumentsContact
          .getDocumentsContact();
      };

      /*******************************************************************************
       *
       * Modal openModalUpdateDocument
       *
       ******************************************************************************/

      self.openModalUpdateDocument = function (document, objData) {
        var modalInstance = $uibModal.open({
          templateUrl: 'documents/modal-envoyer-documents.html',
          controller: 'EnvoyerDocumentsDemandeCtrl',
          controllerAs: '$ctrl',
          resolve: {
            document: function () {
              return document;
            },
            objData: function () {
              return objData;
            },
          },
        });
        modalInstance.result.then(function () {

        }, function () {
          $log.info('Modal dismissed at: ' + new Date());
        });
      }

      /*******************************************************************************
       *
       * Modal openModalDeleteDocument
       *
       ******************************************************************************/
      self.openModalDeleteDocument = (document) => {
        var modalInstance = $uibModal.open({
          templateUrl: 'documents/modal-delete-documents.html',
          controller: 'DeleteDocumentsCtrl',
          controllerAs: '$ctrl',
          resolve: {
            document: function () {
              return document;
            },
          },
        });
        modalInstance.result.then(function () {}, function () {
          $log.info('Modal dismissed at: ' + new Date());
        });
      }

      /*******************************************************************************
       *
       * Modal openModalEnvoyerDocuments
       *
       ******************************************************************************/
      self.openModalEnvoyerDocuments = (document, objData) => {
        var modalInstance = $uibModal.open({
          templateUrl: 'documents/modal-envoyer-documents.html',
          controller: 'EnvoyerDocumentsDemandeCtrl',
          controllerAs: '$ctrl',
          resolve: {
            document: function () {
              return document;
            },
            objData: function () {
              return objData;
            },
          },
        });
        modalInstance.result.then(() => {}, () => {
          $log.info('Modal dismissed at: ' + new Date());
        });
      };
      $scope.$on('updateListDocuments', function (event, data) {
        DocumentsContact.query({
          limit: 999,
          contact_id: Auth.token.user_id
        });
      });

      self.filter = (extension) => {
        return extension.split('.').pop();
      };
    }
  ])
  .controller('ListDocumentsDemandesCtrl', ['$scope', 'DocumentsDemandes',
    '$uibModal',
    function ($scope, DocumentsDemandes, $uibModal) {
      var self = this;
      $scope.$on('documentsDemandeGet', () => {
        self.listDocumentsDemandes = DocumentsDemandes
          .getDocumentsDemandes();
      });
      self.$onInit = function () {
        self.listDocumentsDemandes = DocumentsDemandes
          .getDocumentsDemandes();
        getDocumentsDemandes({
          done: 0
        });
      }

      function getDocumentsDemandes(query) {
        DocumentsDemandes.query(query);
      }
      $scope.$on('updateListDocuments', function (event, data) {
        getDocumentsDemandes({
          done: 0
        });
      });

      /*******************************************************************************
       *
       * Modal openModalEnvoyerDocumentsDemande
       *
       ******************************************************************************/
      self.openModalEnvoyerDocumentsDemande = function (document, objData) {
        var modalInstance = $uibModal.open({
          templateUrl: 'documents/modal-envoyer-documents.html',
          controller: 'EnvoyerDocumentsDemandeCtrl',
          controllerAs: '$ctrl',
          resolve: {
            document: function () {
              return document;
            },
            objData: function () {
              return objData;
            },
          },
        });
        modalInstance.result.then(function () {}, function () {
          $log.info('Modal dismissed at: ' + new Date());
        });
      };

    }
  ])
  .controller('EnvoyerDocumentsDemandeCtrl', ['$rootScope', 'document',
    'objData', '$uibModalInstance', 'Token', 'Upload', 'Config',
    'ConfigsDocuments',
    function ($rootScope, document, objData, $uibModalInstance, Token, Upload,
      Config, ConfigsDocuments) {
      var self = this;
      var token;

      self.$onInit = () => {
        self.viewSend = 0;
        self.maxSizeFiles = 6291456;
        self.document = document;
        self.objData = objData;
        self.urlApi = Config.API_BASE_URL + Config.API_COM_BASE_URL +
          '/documents/';
        self.urlApiMethod = 'POST';
        self.datas = {};

        /* ---------- getCategories ---------- */
        ConfigsDocuments.getCategories({
          check_exist: 0,
          table_id: 34,
        }).then((response) => {
          self.listCategories = response.data;
        }, function errorCallback() {
          return false;
        });

        if (self.objData && self.objData.action && self.objData.action ===
          'updateDocument') {
          self.titleModal = "Mettre à jour un document - " + self.document
            .categorie_label;
          self.urlApi += self.document.id;
          self.urlApiMethod = 'PUT';
        } else if (self.objData && self.objData.action && self.objData
          .action === 'demandeDocument') {
          self.datas.demande_id = self.document.id;
          self.titleModal = "Envoyer un document - " + self.document
            .media_categorie_label;
        } else {
          self.titleModal = "Envoyer un document";
          self.checkboxDemandeDocument = false;
        }

        token = Token.read();
      }

      self.upload = () => {
        if (self.checkboxDemandeDocument && self.demandeSelected && self
          .demandeSelected.id) {
          self.datas.demande_id = self.demandeSelected.id;
        }
        if (!self.checkboxDemandeDocument && self.categorieSelected && self
          .categorieSelected.id) {
          self.datas.categorie_id = self.categorieSelected.id;
        }
        if (self.listUploadDocuments.length > 0 && token) {
          self.viewSend = 1;
          self.listUploadDocuments.progress = 0;
          self.uploadMultiPart(self.listUploadDocuments);
        }
      }

      self.uploadMultiPart = (files) => {
        var returnUploadFiles = {};
        angular.forEach(files, function (file) {

          self.datas.file = file;

          file.upload = Upload.upload({
            url: self.urlApi,
            method: self.urlApiMethod,
            arrayKey: '.i',
            data: self.datas,
          });

          file.upload.then(function (response) {
            if (!Array.isArray(self.arrayFilesUrls)) {
              self.arrayFilesUrls = [];
            }
            self.arrayFilesUrls.push(response.data);
            if (files.length === self.arrayFilesUrls.length) {
              self.viewSend = 2;
              $rootScope.$broadcast('updateListDocuments');
            }
          }, function (response) {
            if (response.status > 0) {
              $scope.errorMsg = response.status + ': ' + response
                .data;
            } else {
              self.errorUploadFiles = response;
            }
          }, function (evt) {
            file.progress = Math.min(100, parseInt(100.0 * evt
              .loaded / evt.total));
          });
        });
      }

      self.totalSizeFiles = () => {
        self.totalSizeListUploadDocuments = 0;
        if (self.listUploadDocuments) {
          angular.forEach(self.listUploadDocuments, function (item, key) {
            if (item.size) {
              self.totalSizeListUploadDocuments += parseFloat(item
                .size);
            }
          });
        }
      }

      self.supprimerDocuments = (tab, index) => {
        tab.splice(index, 1);
        self.totalSizeFiles();
      };

      self.annuler = () => {
        $uibModalInstance.close();
      };
    }
  ])
  .controller('DocumentsProgrammeCtrl', ['DocumentsProgramme', '$scope',
    function (DocumentsProgramme, $scope) {

    },
  ])
  .controller('DocumentsLotCtrl', ['DocumentsLot', '$scope',
    function (DocumentsLot, $scope) {

    },
  ])
  .controller('UploadDocumentsCtrl', ['Upload',
    function (Upload) {
      var self = this;

      self.$onInit = () => {
        if (!self.documents) {
          self.documents = [];
        }
        if (!self.title) {
          self.title = 'Choisir un fichier';
        }
        if (!self.maxSize) {
          self.maxSize = "6MB"
        }
        if (!self.pattern) {
          self.pattern =
            '.jpg,.jpeg,.pdf,.odt,.ods,.docx,.doc,.xls,.xlsx,.png';
        }
        if (!self.useBase64) {
          self.useBase64 = false;
        }
        if (!self.useMultipart) {
          self.useMultipart = true;
          self.urlMultipart = '';
        }
        if (!self.totalSize) {
          self.totalSize = 0;
        }
      }

      self.uploadFiles = (files, invalidFiles) => {
        self.invalidFiles = invalidFiles && invalidFiles[0];
        angular.forEach(files, function (file) {
          if (self.useBase64) {
            files.upload = Upload.base64DataUrl(files).then(function (
              url) {
              fileTmp.base64 = url;
            });
          }
          self.documents.push(file);
          self.totalSize = 0;
          angular.forEach(self.documents, function (item, key) {
            if (item.size) {
              self.totalSize += parseFloat(item.size);
            }
          });
        });
      };
    }
  ])
  .controller('DeleteDocumentsCtrl', ['document', 'Documents', '$rootScope',
    '$uibModalInstance',
    function (document, Documents, $rootScope, $uibModalInstance) {
      var self = this;
      self.document = document;

      self.deleteDocument = () => {
        return Documents.remove({
          id: self.document.id
        }).$promise.then((data) => {
          $uibModalInstance.close();
          $rootScope.$broadcast('updateListDocuments');
        }).catch((error) => {
          console.log(error);
        });
      }

    }
  ])
