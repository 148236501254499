/**
 * Module definition and dependencies
 */
angular.module('App.Auth.Service', [])

  /**
   * Service definition
   */
  .factory('Auth', function (
    $q, $state, $store, $analytics, Token, $rootScope, Config
  ) {

    //Helper vars
    let refreshPromise = null;

    /**
     * Service class
     */
    const Auth = {

      //Token model instance
      token: null,
      redirectState: null,
      lastState: null,

      /**
       * Check if we're currently authenticated
       */
      isAuthenticated() {
        return (this.token && this.token.isValid());
      },

      /**
       * Check if expired
       */
      isExpired() {
        return (this.token && this.token.isExpired());
      },

      /**
       * Check if about to expire
       */
      isExpiring(offset = 60) {
        return (this.token && this.token.isExpiring(offset));
      },

      /**
       * Has role check
       */
      hasRole(role) {
        return (this.isAuthenticated() && this.token.hasRole(role));
      },

      /**
       * Has secure status check
       */
      hasSecureStatus() {
        return (this.isAuthenticated() && this.token.hasSecureStatus());
      },

      /**************************************************************************
       * Access token management
       ***/

      /**
       * Get raw access token string
       */
      getAccessToken() {
        if (this.token) {
          return this.token.accessToken;
        }
        return '';
      },

      /**
       * Validate access token
       */
      validateAccessToken(accessToken) {

        //Create token model
        const token = new Token(accessToken);

        //Check if valid
        if (!token.isValid()) {
          throw new Error('Invalid access token');
        }

        //Check if expired
        if (token.isExpired()) {
          throw new Error('Expired access token');
        }

        //Return model
        return token;
      },

      /**************************************************************************
       * State management
       ***/

      /**
       * Go to login state
       */
      goToLoginState(redirectState, loginParams) {

        //Remember redirect state
        this.redirectState = redirectState || null;

        //Already authenticated? Go straight to redirect state
        if (this.isAuthenticated()) {
          return this.goToPostLoginState();
        }

        //Redirect to login state
        return $state.go('auth.login', loginParams || null);
      },

      /**
       * Go to post login state
       */
      goToPostLoginState() {
        //No state? Go gome

        return $state.go('mes-projets');

      },

      /**
       * Set last successful target state
       */
      setLastState(state) {
        this.lastState = state;
      },

      /**************************************************************************
       * Login and logout
       ***/

      /**
       * Login with credentials
       */
      loginWithCredentials(credentials, remember) {
        return Token
          .obtain('password', credentials, remember)
          .then(token => this.validateAccessToken(token))
          .then(token => this.onAuthenticated(token))
          .then(() => this.goToPostLoginState());
      },

      /**
       * Login with token
       */
      loginWithToken(token) {
        return $q
          .resolve()
          .then(() => this.validateAccessToken(token))
          .then(token => this.onAuthenticated(token))
          .then(() => this.goToPostLoginState());
      },

      /**
       * Logout
       */
      logout(isAutomatic, params) {

        //Logout on server
        return Token
          .forget()
          .catch(() => true) //NOTE: not ideal, but best to at least logout
          .then(() => this.onUnauthenticated(isAutomatic, params));
      },

      /**
       * Refresh
       */
      refresh() {

        //Already refreshing?
        if (refreshPromise) {
          return refreshPromise;
        }

        return refreshPromise = $q.reject().finally(() => refreshPromise =
          null);

        //Obtain token from server
        /* return refreshPromise = Token
         .obtain('refresh_token')
         .then(token => this.validateAccessToken(token))
         .then(token => this.onAuthenticated(token, false))
         .finally(() => refreshPromise = null);*/
      },

      /**
       * Re-authenticate
       */
      reAuthenticate(credentials, secure) {
        //Append secure status if needed
        if (secure) {
          credentials.secureStatus = true;
        }

        //Obtain new token with secure status
        return Token
          .obtain('password', credentials)
          .then(token => this.validateAccessToken(token))
          .then(token => this.onAuthenticated(token, false));
      },

      /**************************************************************************
       * Auth status change handlers
       ***/

      /**
       * On authenticated
       */
      onAuthenticated(token, refresh = true) {

        //Set token model
        this.token = token;
        //Refresh user/club data if needed
        /*if (refresh) {
            $store.club.get(true);
            $store.user.get(true).then(user => $analytics.set.userId(user.id));
        }*/

        if ($analytics.isEnabled()) {
          if (Config.APP_NAME && this.token.claims.rattachement) {
            $analytics.set.appId(Config.APP_NAME + '_' + this.token
              .rattachement);
          }
          if (this.token.user_id) {
            $analytics.set.userId(this.token.user_id);
          }
        }

        //Otherwise just return the token
        $rootScope.$broadcast('onAuthenticated', {
          token: token
        });
        return this.token;
      },

      /**
       * On unauthenticated
       */
      onUnauthenticated(isAutomatic, params) {

        //Clear token model
        this.token = null;

        params.status = isAutomatic ? 'session-expired' : null;

        //Clear stores
        for (const key in $store) {
          if (key !== 'club' && $store.hasOwnProperty(key) && $store[key]
            .clear) {
            $store[key].clear();
          }
        }

        //Go to login state
        this.goToLoginState(this.lastState, params);
      },

      /**************************************************************************
       * Init
       ***/

      /**
       * Initialize
       */
      init() {

        //Find existing access token and validate it. If invalid, clear.
        Token
          .existing()
          .then(token => this.validateAccessToken(token))
          .then(token => this.onAuthenticated(token))
          .catch(() => Token.clear());
      },
    };

    //Init
    Auth.init();


    return Auth;
  });
