/**
 * Module definition and dependencies
 */
angular.module('App.Timeline.Controller', [])

  /**
   * Controller
   */
  .controller('TimelineCtrl', [
    function () {

    }
  ])
