/**
 * Module definition and dependencies
 */
angular.module('App.Agenda', [
    'App.Agenda.Controller',
  ])

  /**
   * Config
   */
  .config($stateProvider => {
    $stateProvider.state('agenda', {
      parent: 'app',
      url: '/agenda',
      component: 'agendaRoute',

    });
  })

  /**
   * Route component
   */
  .component('agendaRoute', {
    controller: 'AgendaCtrl',
    templateUrl: 'agenda/agenda.html',
  });
