/**
 * Module definition and dependencies
 */
angular.module('App.Mail.Controller', [
    'membresService.Service',
    'contactService.Service',
  ])

  /**
   * Controller
   */
  .controller('MailCtrl', ['Membre', 'ContactMessage', function (Membre,
    ContactMessage) {


    var self = this;
    self.send = false;
    self.monMessage = new ContactMessage();
    self.monInterlocuteur = function monInterlocuteur(id) {
      return Membre.query({
        membreId: id,
      }).$promise.then(function (response) {
        return self.monInterlocuteur = response;
      }).catch(function (err) {
        console.log('promise error', err);
      });
    };

    self.sendMail = function sendMail() {
      self.monMessage.membre_id = self.interlocuteurId;
      self.monMessage.$save().then(function (response) {
        self.send = true;
        if (self.modalInstance) {
          self.modalInstance.dismiss('close')
        }

      }).catch(function (error) {
        console.log(error);
      });
    }
    this.$onInit = function () {
      if (self.interlocuteurId) {
        self.monInterlocuteur(self.interlocuteurId);
      }

    };
  }]).controller('ModalMailCtrl', ['$uibModalInstance', 'interlocuteurId',
    function ($uibModalInstance, interlocuteurId) {
      var self = this;
      self.interlocuteurId = interlocuteurId;
      self.modalInstance = $uibModalInstance;
    }
  ]);
