/**
 * Module definition and dependencies
 */
angular.module('App.MonContact.Controller', [
    'messageService.Service',
])

/**
 * Controller
 */
.controller('MonContactCtrl',['$rootScope', 'Message',
    function($rootScope, Message) {
        var self = this;
        self.listSujetsMessage = [
            'Sujet 1',
            'Sujet 2',
            'Sujet 3',
        ];
        self.$onInit = function () {
        };
        self.sendMessage = function() {
            if($rootScope.monInterlocuteur) {
                self.message.membre_id = $rootScope.monInterlocuteur.id;
                return Message.save(self.message).$promise.then(function (response) {
                    self.resultMessage = {
                        'type': 'alert-success',
                        'message': 'Votre message a bien été envoyé',
                    }
                }).catch(function (error) {
                    console.log(error);
                    self.resultMessage = {
                        'type': 'alert-danger',
                        'message': 'Échec de l\'envoi du message',
                    }
                });
            }
        }
}]);


