/**
 * Module definition and dependencies
 */
angular.module('App.MesActions', [
    'App.MesActions.Controller',
  ])
  .config($stateProvider => {
    $stateProvider.state('mes-actions', {
      parent: 'app',
      url: '/mes-actions',
      component: 'mesActions',
      data: {
        auth: true,
      },
    });

  })

  /**
   * Route component
   */
  .component('mesActions', {
    controller: 'MesActionsCtrl',
    templateUrl: 'mes-actions/mes-actions.html',
  })
  .component('monAction', {
    controller: 'MesActionsCtrl',
    templateUrl: 'mes-actions/mon-action.html',
  })
