/**
 * Module definition and dependencies
 */
angular.module('App.MesActualites.Controller', [
    'contactService.Service',
    'programmesService.Service',
    'profilService.Service',
    'transactionsService.Service',
  ])

  /**
   * Controller
   */
  .controller('MesActualitesCtrl', ['$scope', 'Transactions',
    '$stateParams', '$rootScope', '$state',
    function ($scope, Transactions, $stateParams, $rootScope, $state) {
      $scope.listActualites = [];
      $scope.programmeId = [];
      $scope.$on('transactionsGetProgrammeActualites', () => {
        $scope.listTransactions = Transactions
          .getTransactions();
        this.setListActualites($scope.listTransactions);
      });
      this.setListActualites = (listTransactions) => {
        angular.forEach(listTransactions, (value) => {
          if (!value.programmeActualites || value.programmeActualites
            .length === 0) {
            return;
          }
          let findProgramme = $scope.programmeId.find(item => item ===
            value
            .programme_id);
          $scope.programmeId.push(value.programme_id);
          if (!findProgramme || $scope.programmeId.length === 0) {
            angular.forEach(value
              .programmeActualites, (value) => {
                $scope.listActualites.push(
                  value
                );
              });
          }
        });

      }
      this.$onInit = () => {
        if (!$rootScope.stateParamsId) {
          return $state.go('mes-projets');
        }
        $scope.actualiteId = $stateParams.actualiteId;
        $scope.listTransactions = Transactions
          .getTransactions();
        this.setListActualites($scope.listTransactions);
        $scope.noWrapSlides = false;
        $scope.active = 0;
        $scope.myInterval = 5000;
      };

    }
  ])
