/**
 * Module definition and dependencies
 */
angular.module('App.Footer.Component', [])

  /**
   * Component
   */
  .component('appFooter', {
    templateUrl: 'app/footer/footer.html',
    controller: ('FooterCtrl', ['$stateParams', '$scope',
      function ($stateParams, $scope) {
        $scope.currentTransactionId = $stateParams.id;
        $scope.$watch('currentTransactionId', () => {
          return $scope.currentTransactionId;
        });

      },
    ]),
  });
