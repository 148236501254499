/**
 * Module definition and dependencies
 */
angular.module('App.MonLot', [
    'App.MonLot.Controller',
    'transactionsService.Service',
  ])

  /**
   * Config
   */
  .config($stateProvider => {
    $stateProvider.state('monLot', {
      parent: 'app',
      url: '/mon-lot/{id: int}',
      component: 'monLotRoute',
      data: {
        auth: true,
      }
    });
  })
  .config($stateProvider => {
    $stateProvider.state('monInvestissement', {
      parent: 'app',
      url: '/mon-investissement/{id: int}',
      component: 'monInvestissement',
      data: {
        auth: true,
      }
    });
  })

  /**
   * Route component
   */
  .component('monLotRoute', {
    controller: 'MonLotCtrl',
    templateUrl: 'mon-lot/mon-lot.html',
  })
  .component('monInvestissement', {
    controller: 'MonLotCtrl',
    templateUrl: 'mon-lot/mon-investissement.html',
  });
