/**
 * Module definition and dependencies
 */
angular.module('App.Messages', [
    'App.Messages.Controller',
  ])

  /**
   * Config
   */
  .config($stateProvider => {
    $stateProvider.state('messages', {
      parent: 'app',
      url: '/messages',
      component: 'messagesRoute',
      data: {
        auth: true,
      }
    });
  })

  /**
   * Route component
   */
  .component('messagesRoute', {
    controller: 'MessagesCtrl',
    templateUrl: 'messages/messages.html',
    bindings: {
      transaction: '<',
    }
  });
