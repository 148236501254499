/**
 * Module definition and dependencies
 */
angular.module('App.Controller', [
    'profilService.Service',
    'transactionsService.Service',
    'contactService.Service',
    'programmesService.Service',
    'lotsService.Service',
    'documentsService.Service',
    'App.Auth.Service',
  ])

  /**
   * Main application controller
   */
  .controller('AppCtrl', ['$scope', '$state', 'Profil', 'Transactions',
    'ContactActualites', 'Lot', 'Programme', '$rootScope',
    'ProgrammeActualites', '$stateParams', 'DocumentsDemandes', 'Auth',
    'DocumentsContact', 'ProgrammeTimeline', 'ProgrammeActualitesMedias',
    'DocumentsProgramme', 'DocumentsLot', 'ProgrammeImages', 'moment',
    '$filter', 'LotDetails', 'Configurations', '$storage', '$location',

    function ($scope, $state, Profil, Transactions, ContactActualites, Lot,
      Programme, $rootScope, ProgrammeActualites, $stateParams,
      DocumentsDemandes, Auth, DocumentsContact, ProgrammeTimeline,
      ProgrammeActualitesMedias, DocumentsProgramme, DocumentsLot,
      ProgrammeImages, moment, $filter, LotDetails, Configurations, $storage,
      $location
    ) {

      let self = this;
      self.lots = [];
      self.programmes = [];
      // Initialisation du nombre de transaction
      $rootScope.totalTransaction = 0;
      // Id de la transaction en cours
      $rootScope.stateParamsId = $stateParams.id;
      // theme commercialisateur
      $rootScope.cheminThemePromoteurCss = '';
      $rootScope.loader = true;
      $scope.$on('$locationChangeStart',
        function (event, next, current) {
          if (next.indexOf('images-') > 0) {
            event.preventDefault();
            return;
          }
        });
      self.$onInit = () => {
        const promoteurUrl = $location.search().promoteur;
        if (promoteurUrl) {
          $rootScope.promoteur = $storage.local.set('promoteur',
            promoteurUrl);
        }


        $rootScope.promoteur = $storage.local.get('promoteur');
        Profil
          .get().then(() => {
            // Profil
            const profil = Profil.getProfil();
            // Récupérer les transactions du contact
            $scope.transactions = Transactions.query({
              etat: 4,
              type: '2,3,4',
            }).then(() => {
              self.listTransactions = Transactions
                .getTransactions();
              if (self.listTransactions.length === 1) {
                $rootScope.stateParamsId = self
                  .listTransactions[0]
                  .id;
              }
              $rootScope.totalTransaction = self
                .listTransactions
                .length;
              angular.forEach(self.listTransactions, (item,
                key) => {
                //Programme
                Programme.get({
                  programmeId: item.programme_id,
                }).then(() => {
                  const programme = Programme
                    .getProgramme();
                  Transactions.setTransactionProgramme(
                    programme,
                    key);
                  $rootScope.loader = false;
                });
                // Document programme
                DocumentsProgramme.query({
                  limit: 50,
                  programme_id: item.programme_id,
                }).then(() => {
                  const mesDocumentsProgramme =
                    DocumentsProgramme
                    .getDocumentsProgramme();
                  Transactions
                    .setTransactionProgrammeDocument(
                      mesDocumentsProgramme,
                      key);
                });
                // Document lot
                DocumentsLot.query({
                  limit: 50,
                  lot_id: item.lot_id,
                }).then(() => {
                  const mesDocumentsLot = DocumentsLot
                    .getDocumentsLot();
                  Transactions
                    .setTransactionDocumentLot(
                      mesDocumentsLot, key);
                });
                // Programme news
                ProgrammeActualites.query({
                  programmeId: item.programme_id,
                }).then(() => {
                  const listActualitesProgramme =
                    ProgrammeActualites
                    .getActualitesProgramme();

                  angular.forEach(
                    listActualitesProgramme, (
                      item,
                      key) => {
                      ProgrammeActualitesMedias
                        .query({
                          actualite_id: item.id,
                        }).then(() =>
                          listActualitesProgramme[key]
                          .medias =
                          ProgrammeActualitesMedias
                          .getMediasActualitesProgramme()
                        );
                    });
                  Transactions
                    .setTransactionProgrammeActualites(
                      listActualitesProgramme,
                      key);

                });
                // list of programme screen
                ProgrammeImages.get({
                  programmeId: item.programme_id,
                  promoteurId: profil
                    .commercialisateur_id,
                }).then(() => {
                  const containProgrammes =
                    ProgrammeImages
                    .getProgrammeContenus();
                  Transactions
                    .setTransactionProgrammeMedias(
                      containProgrammes.medias, key);
                });
                // Timeline Programme
                ProgrammeTimeline.query({
                  programmeId: item.programme_id,
                }).then(() => {
                  const listTimelineProgramme =
                    ProgrammeTimeline
                    .getTimelineProgramme();
                  const dateReservation = {
                    date: item.date_creation,
                    days_ago: 1,
                    readonly: true,
                    titre: 'Date de réservation lot n° ' +
                      item.lot_nom,
                  };
                  listTimelineProgramme.push(
                    dateReservation);
                  Transactions
                    .setTransactionProgrammeTimeline(
                      listTimelineProgramme,
                      key);
                });
                // Lot 
                Lot.get({
                  lotId: item.lot_id,
                }).then(() => {

                  const lot = Lot.getLot();

                  Transactions.setTransactionLot(lot,
                    key);
                });
                LotDetails.get({
                  lotId: item.lot_id,
                }).then(() => {
                  const lotDetail = LotDetails
                    .getLotDetails();

                  Transactions.setTransactionLotDetails(
                    lotDetail,
                    key);
                });
              });


            })
            $rootScope.cheminThemePromoteurCss =
              'themes-promoteurs/theme-' + $rootScope.promoteur +
              '.css';
            ContactActualites.query({
              contactId: profil.id,
            }).then(() => {
              self.listActualitesContact = ContactActualites
                .getActualitesContact();
              const actionInProgress = $filter('filter')(self
                .listActualitesContact,
                'date.date', $scope.sortByDate);
              $rootScope.actionInProgress = actionInProgress
                .length;
            });
          });
        //Document du contact
        DocumentsContact.query({
          limit: 999,
          contact_id: Auth.token.user_id
        }).then(() => {
          self.mesDocumentsContact = DocumentsContact
            .getDocumentsContact();
        });
        // recupere les document en attente 
        DocumentsDemandes.query({
          done: 0
        }).then(() => {
          self.listDocumentsDemandes = DocumentsDemandes
            .getDocumentsDemandes();
          // ajout d'une variable globale afin de savoir si il y a un document en attente
          $rootScope.documentDemandes = self.listDocumentsDemandes
            .length;
        });
      };
      $scope.sortByDate = (actual, expected) => {
        let now = moment();
        return moment(actual).isAfter(now);
      };
      $scope.$watch(() => {
        return self.currentPage = $state.current.name;
      });
    }
  ]);
