/**
 * Module definition and dependencies
 */
angular.module('App.Component', [
    'App.Controller',
    'App.Header.Component',
    'App.Sidebar.Component',
    'angularPromiseButtons',
    'App.Footer.Component',
    'ngSanitize',
    'ngAnimate',
    'ui.bootstrap',
    'configurateursService.Service'
  ])

  .config(function (angularPromiseButtonsProvider) {
    angularPromiseButtonsProvider.extendConfig({
      spinnerTpl: '<span class="spinner-icon"></span>',
      disableBtn: true,
      btnLoadingClass: 'is-loading',
      addClassToCurrentBtnOnly: false,
      disableCurrentBtnOnly: false,
      minDuration: 2777,
      CLICK_EVENT: 'click',
      CLICK_ATTR: 'ngClick',
      SUBMIT_EVENT: 'submit',
      SUBMIT_ATTR: 'ngSubmit',
      BTN_SELECTOR: 'button',
    });
  })

  /**
   * Application configuration
   */
  .config(($locationProvider, $urlServiceProvider, $httpProvider,
    $analyticsProvider,
    $apiProvider, $logProvider, $qProvider, $stateProvider, Config) => {

    //Determine app base url
    if (!Config.APP_BASE_URL) {
      let port = Config.APP_PORT || window.location.port;
      Config.APP_BASE_URL =
        window.location.protocol + '//' + window.location.hostname +
        ((port !== 80) ? (':' + port) : '') + (Config.APP_BASE_PATH || '');
    }

    //Determine API base url
    if (!Config.API_BASE_URL) {
      let port = Config.API_PORT || window.location.port;
      Config.API_BASE_URL =
        window.location.protocol + '//' + window.location.hostname +
        ((port !== 80) ? (':' + port) : '') + (Config.API_BASE_PATH || '');
    }

    //Disable unhandled rejection warnings
    $qProvider.errorOnUnhandledRejections(false);

    //Enable HTML 5 mode browsing and set default route
    $locationProvider.html5Mode(false);
    $urlServiceProvider.rules.otherwise('/error/page-not-found');

    //Disable legacy $http promise methods and set default headers
    $httpProvider.useLegacyPromiseExtensions = false;
    //$httpProvider.defaults.headers.common['X-Version'] = Config.APP_VERSION;

    //Configure API (escape port number)
    $apiProvider.setBaseUrl(Config.API_BASE_URL);
    $apiProvider.setDefaultModel('$baseModel');
    $apiProvider.setEnforceDataFormat(Config.API_ENFORCE_DATA_FORMAT);
    $apiProvider.setConfig('timeout', Config.API_NETWORK_TIMEOUT * 1000);
    $apiProvider.setVerbose(Config.ENV === 'dev');

    //Configure analytics
    $analyticsProvider.setEnabled(
      Config.ANALYTICS_ENABLED && Config.ANALYTICS_TRACKING_ID
    );

    //Disable all console logging in production
    if (Config.ENV === 'production') {
      $logProvider.disable('all');
    }

    //App base state
    $stateProvider.state('app', {
      url: '',
      abstract: true,
      data: {
        auth: false,
      },
      component: 'appRoute',
    });
  })

  /**
   * Route component
   */
  .component('appRoute', {
    templateUrl: 'app/app.html',
    controller: 'AppCtrl',
    bindings: {},
  })

  /**
   * Run logic
   */
  .run(($log, $analytics, $rootScope, $trace, $transitions, Config) => {
    //Log info to console
    $log.info(Config.ENV, 'v' + Config.APP_VERSION, Config.APP_REVISION);

    //Create site wide tracker
    if ($analytics.isEnabled()) {
      $analytics.create(Config.ANALYTICS_TRACKING_ID);
      $analytics.set.appName(Config.APP_NAME);
      $analytics.set.appVersion(Config.APP_VERSION);
    }

    //Set config in prototype of scope
    const protoScope = Object.getPrototypeOf($rootScope);
    for (const key in Config) {
      if (Config.hasOwnProperty(key)) {
        protoScope[key] = Config[key];
      }
    }

    //Enable transition trace
    if (Config.ENV === 'dev') {
      $trace.enable('TRANSITION');
    }

    /**
     * Before hook for transitions
     */
    $transitions.onBefore({}, transition => {

      //Add resolvable alias for $transition$
      transition.addResolvable({
        token: 'transition',
        resolveFn: () => transition,
      });

      //Get to and from states
      const to = transition.to();
      const from = transition.from();

      //Prevent navigation to certain states from initial requests
      if (to.data && to.data.notInitial && !from.name) {
        $log.warn('State', to.name, 'cannot be accessed directly.');
        return transition.router.stateService.target('home');
      }
    });

    /**
     * Success hook for transitions
     */
    $transitions.onSuccess({}, () => {

      //Track page views
      if ($analytics.isEnabled()) {
        $analytics.set.page();
        $analytics.track.pageview();
      }
    }, {
      priority: 100
    });

    $transitions.onSuccess({}, (transition) => {
      $rootScope.title = transition._targetState._definition.data.title;
    }, {});
  }).factory('HeadersInterceptor', function () {
    //Service placeholders
    function isTemplateRequest(request) {
      return request && request.url.indexOf('.html') !== -1;
    }

    function appendHeaders(request) {

      //Get auth service

      //Initialize headers if needed
      request.headers = request.headers || {};

      //Get access token and append to header if present
      //request.headers['X-Arka-Membre-Id'] = '71355';
      //request.headers['X-Arka-Promoteur-Id'] = '36';

      //Return request config
      return request;
    }

    /**
     * Interceptor object
     */
    return {

      /**
       * Append access token header to request
       */
      request: function request(_request) {
        if (isTemplateRequest(_request)) {
          return _request;
        }

        _request = appendHeaders(_request);

        //Append access token
        return _request;
      }
    };
  }).config(["$httpProvider", "Config", function ($httpProvider, Config) {
    //Add auth interceptor (must be before the error interceptor)
    //if (Config.MOCK_AUTH_HEADERS) {
    $httpProvider.interceptors.unshift('HeadersInterceptor');
    //}
  }])
  .config(function ($urlRouterProvider) {
    $urlRouterProvider.when('', '/');
    $urlRouterProvider.otherwise('/');
  });
