angular.module('Login.Service', [])
  .provider('Login', function LoginProvider() {
    let promoteur;

    this.setPromoteurId = function (pId) {
      promoteur = pId;
    };
    this.$get = ['$http', 'Config', '$storage', function ($http, Config,
      $storage) {
      return {
        login: function (email, password) {
          return $http.post(Config.IMMODESK_BASE_URL +
            '/public/auth/login', {
              'promoteur': promoteur,
              'email': email,
              'password': password,
              'discr': 'client',
            }).then(function (result) {
            return result.data;
          });
        },
        setPromoteurId: function (pId) {
          $storage.local.set('promoteur', pId);
          promoteur = pId;
        }
      };
    }];
  })
  .config(['LoginProvider', function (LoginProvider) {
    let promoteur = null;
    // Parsing query string
    let url = window.location.href;
    let name = 'promoteur';
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (results && results[2])
      promoteur = decodeURIComponent(results[2].replace(/\+/g, " "));

    LoginProvider.setPromoteurId(promoteur);
  }]);
