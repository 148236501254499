/**
 * Module definition and dependencies
 */
angular.module('App.Configurations', [
    'App.Configurations.Controller',
])

/**
 * Config
 */
.config($stateProvider => {
    $stateProvider.state('configurations', {
        parent: 'app',
        url: '/configurations',
        component: 'configurations',
        data : {
            auth : true,
        }
    });
})

/**
 * Route component
 */
.component('configurations', {
    controller: 'ConfigurationsCtrl',
    templateUrl: 'configurations/configurations.html',
})
.component('lastConfigurationLot', {
    controller: 'LastConfigurationLotCtrl',
    templateUrl: 'configurations/last-configuration-lot.html',
    bindings: {
        lotId: '<',
    }
});
